.App {
  background-color: #282c34;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height:100vh;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.social-icon {
  padding:1rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.a {
  text-decoration: none;
}

.App-header {
  min-height: 2.5em;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 50pt;
}

.talks-body {
  padding: 2em;
  background-color: #e8eef9;
  align-items: left;
  min-width: 200pt;
  margin: 10vh;
  max-width: 80vh;
}

.talks-body ul {
  text-align: left;
  list-style: none;

}

.talks-body a {
  color: #0c5678;
  text-decoration: none;
}
.talks-body a:hover {
  text-decoration: underline;
}
.talk-name {
  padding: 10pt;
}
.talk-location {
  padding: 8pt;
  margin: 8pt;
  border-radius: 5pt;
  background-color: #282c34;
  color: white;
  padding:6px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
}
.talk-technologies {
  font-style:italic;
}

.App-link {
  color: #61dafb;
  text-decoration: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
